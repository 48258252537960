import React from 'react';
import * as CONSTS from './constants.js'
import ImageUpload from '../../Utils/ImageUpload.js'
import FlavorProfile from './FlavorProfile.js'

class AddEditBean extends React.Component {
  constructor(props) {
    super(props);
    this.setBean = this.setBean.bind(this);
    this.updateImage = this.updateImage.bind(this);
    this.onUpdateDetails = this.onUpdateDetails.bind(this);
    this.onUpdateFlavorProile = this.onUpdateFlavorProile.bind(this);

    this.state = CONSTS.BEAN_DEFAULT_PROPS;
  }

  componentDidUpdate(prevProps) {
    let isEdit = this.props.itemSelectedForEdit;

    // Only do something if there's a change in the batchToEdit
    if (this.props !== prevProps) {

      // If there's something to edit or the props don't match the default
      if (isEdit) {

        // Save the selected label we selected for edit
        if (this.props.itemSelectedForEdit) {
          this.setState(this.props.itemSelectedForEdit);
        }
      } else {
        this.setState(CONSTS.BEAN_DEFAULT_PROPS);
      }
    }
  }

  async onUpdateDetails(event) {
    var state = this.state;
    state[event.target.name] = event.target.value;
    await this.setState(state);
  }

  updateImage(imageUpload) {
    let imageBase64 = imageUpload.image;
    this.setState({imageBase64});
  }

  onUpdateFlavorProile(newProfile) {
    let flavorProfile = newProfile;
    this.setState({flavorProfile});
  }

  async setBean() {

    if (this.state.value) {
      let documentToEdit = this.state.value;
      const publicCollectionRef = this.props.firebase.db.collection("beansPublic");
      await publicCollectionRef.doc(documentToEdit).set(this.state).then(() => {
        console.log('set public beans');
      });
      const collectionRef = this.props.firebase.db.collection("beans");
      await collectionRef.doc(documentToEdit).set(this.state).then(() => {
        console.log('set bean');
      });

      let state = CONSTS.BEAN_DEFAULT_PROPS;
      this.setState(state);
    }
  }

  render() {
    return (
      <div>
      Label:  <input name="label"  onChange={this.onUpdateDetails} value={this.state.label} size="30" type="text"></input><br />
      Uniquie DB Value Label:  <input name="value"  onChange={this.onUpdateDetails} value={this.state.value} size="10" type="text"></input><br />
      Display Label:  <input name="displayLabel"  onChange={this.onUpdateDetails} value={this.state.displayLabel} size="20" type="text"></input><br />
      Country:  <input name="country"  onChange={this.onUpdateDetails} value={this.state.country} size="20" type="text"></input><br />
      Price: <input name="price"  onChange={this.onUpdateDetails} value={this.state.price} size="6" type="text"></input><br />
      Purchase Lbs: <input name="purchaseLbs"  onChange={this.onUpdateDetails} value={this.state.purchaseLbs} size="6" type="text"></input><br />
      Notes: <textarea name="notes"  onChange={this.onUpdateDetails} value={this.state.notes} type="text"></textarea><br />
      Alchemist Notes: <textarea name="alchemistNotes"  onChange={this.onUpdateDetails} value={this.state.alchemistNotes} type="text"></textarea><br />
      <FlavorProfile flavorProfile={this.state.flavorProfile} onUpdate={this.onUpdateFlavorProile} itemSelectedForEdit={this.props.itemSelectedForEdit}/>
      <ImageUpload onUpdate={this.updateImage} image={this.state.imageBase64} />
      <button onClick={this.setBean}>Update Bean</button>
      </div>
    );
  }
}

export default AddEditBean;
