export const LANDING = '/';
export const LOGIN = '/login';
export const LOGOUT = '/logout';

export const SIGNIN = '/signin';
export const SIGNOUT = '/signout';
export const SIGNUP = '/signup';

export const CHOCOLATE = {
  INGREDIENT : '/chocolate/ingredient',
  BATCH : '/chocolate/batch',
  BAR : '/chocolate/bar',
  BAR_LOOKUP : '/chocolate/bar/:barId',
  BAR_ADD_EDIT : '/chocolate/barAddEdit',
  BAR_DEPENDENCIES : '/chocolate/barConfigure',
  INTENTION : '/chocolate/intention',
  TASTING : '/chocolate/tasting/:tastingId',
  TASTING_ROOT : '/chocolate/tasting',
  TASTING_ADD_EDIT : '/chocolate/tastingAddEdit',
  LANDING : '/chocolate',
  BEAN : '/chocolate/bean'
};
