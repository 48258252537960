export const MOLD_SIZE_DEFAULT_PROPS = {
  label : '',
  barLabel : '',
  moldLabel : '',
  barsPerMold : '',
  moldCountOwned : '',
  usedOften : false,
  barWeightInGrams : '',
  id : '',
  barDimensionsInMm : {
    x : '',
    y : '',
    z : ''
  },
  barPieceCount : '',
  barServingSizeInPieces : '',
  purchaseFromUrl : '',
  imageBase64 : '',
  packagingCameoPrintFileLocation : '',
  packagingCadFileLocation : '',
  notes : ''
}

export const MOLD_SIZE_DEFAULT_PUBLIC_PROPS = {
  label : '',
  imageBase64 : '',
  barWeightInGrams : ''
}
