export const INTENTION_DEFAULT_PROPS = {
  label : '',
  display : '',
  notes : ''
}

export const INTENTION_DEFAULT_PUBLIC_PROPS = {
  label : '',
  display : '',
  notes : ''
}
