import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { withFirebase } from '../Firebase';

import * as ROUTES from '../../constants/routes';

const SignUpPage = () => (
  <div>
    <h1>SignUp</h1>
    <SignUpForm />
  </div>
);
const INITIAL_STATE = {
  username: '',
  email: '',
  passwordOne: '',
  passwordTwo: '',
  error: null,
  ip : ''
};

class SignUpFormBase extends Component {
  constructor(props) {
    super(props);
    this.state = { INITIAL_STATE };
  }

  onSubmit = event => {
    //const { username, email, passwordOne } = this.state;
/*
        this.props.firebase
          .doCreateUserWithEmailAndPassword(email, passwordOne)
          .then(authUser => {
            this.setState({ ...INITIAL_STATE });
          })
          .catch(error => {
            this.setState({ error });
          });
*/
        event.preventDefault();

        // Eventually put this after success or error
        this.props.history.push(ROUTES.LANDING);
  }

  onChange = event => {
    this.setState({value: event.target.value});
  };

  render() {
    const {
      username,
      email,
      passwordOne,
      passwordTwo,
      error,
    } = this.state;

    const isInvalid =
      passwordOne !== passwordTwo ||
      passwordOne === '' ||
      email === '' ||
      username === '';
    return (
      <form onSubmit={this.onSubmit}>
        <input
         name="username"
         value={this.username}
         onChange={this.onChange}
         type="text"
         placeholder="Full Name"
       />
       <input
         name="email"
         value={email}
         onChange={this.onChange}
         type="text"
         placeholder="Email Address"
       />
       <input
         name="passwordOne"
         value={passwordOne}
         onChange={this.onChange}
         type="password"
         placeholder="Password"
       />
       <input
         name="passwordTwo"
         value={passwordTwo}
         onChange={this.onChange}
         type="password"
         placeholder="Confirm Password"
       />
       <button disabled={isInvalid} type="submit">Sign Up</button>

       {error && <p>{error.message}</p>}
      </form>
    );
  }
}

const SignUpLink = () => (
  <p>
    Don't have an account? <Link to={ROUTES.SIGNUP}>Sign Up</Link>
  </p>
);

const SignUpForm = withRouter(withFirebase(SignUpFormBase));

export default SignUpPage;

export { SignUpForm, SignUpLink };
